<template>
    <v-container fluid>
        <Header @back-to-roles="backToRoles" :role="roleDetail" />

        <v-card class="py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
            <template>
                <v-row justify="space-between" class="align-center">
                    <v-col cols="12" md="auto" class="d-flex align-center">
                        <SearchComponent :search="filter.name" @update:search="filter.name = $event"
                            :placeholder="$t('placeHolder.searchByName')" />

                        <FilterDialog :projects="getProjectsName" :tags="getTagsName" @apply="applyFilter" />
                    </v-col>

                    <v-col cols="12" md="auto">
                        <v-row justify="end" class="align-center">
                            <SettingsMenu :filterItems="filteredMenuHeaders" />
                        </v-row>
                    </v-col>
                </v-row>

                <Table :headers="filteredHeaders" :items="filtersMembers" itemKey="uid"
                    @select-item="onCheckboxSelected" :clearSelection="clearSelection" :role="roleDetail" />

                <div class="d-flex align-end justify-end">

                    <v-btn depressed color="red" class="font-inter text-capitalize white--text mt-2"
                        :disabled="!selectedMembersHasData" @click="onDeleteMembers">
                        {{ $t('testruns.delete') }}
                        {{ selectedMembersHasData ? `(${getSelectedMembersCount})` : '' }}
                    </v-btn>

                    <v-menu v-model="menuOpen" :close-on-content-click="false" offset-y top right
                        class="menu-shadow-custom">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" depressed color="blue" :disabled="!selectedMembersHasData"
                                class="font-inter text-capitalize ml-4 mt-2 white--text">
                                {{ $t('reassignTo') }}
                                {{ selectedMembersHasData ? `(${getSelectedMembersCount})` : '' }}
                                <v-icon>
                                    {{ menuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                </v-icon>
                            </v-btn>
                        </template>

                        <v-list class="actions-list font-inter">
                            <v-list-item class="actions-item text-start" v-for="(role, index) in getRolesName"
                                :key="index" @click="onSelectRole(role.roleId, role.roleName)">
                                <v-list-item-title class="font-inter actions-item-title">{{ role.roleName
                                    }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                </div>
            </template>

            <!-- <EmptyTag v-else @create-new-tag="onCreateTag" /> -->

        </v-card>
    </v-container>
</template>

<script>
import * as _ from 'lodash'
import { createNamespacedHelpers } from 'vuex';

import makeRoleService from '@/services/api/role';
import makeProjectService from '@/services/api/project';
import makeTagService from '@/services/api/tag';

import Header from '@/components/Admin/Role/Members/Header';
import FilterDialog from '@/components/Admin/Role/Members/FilterDialog.vue';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import Table from '@/components/Admin/Role/Members/Table.vue';

import { showConfirmationDialog } from '@/utils/toast';

const { mapState } = createNamespacedHelpers('user');

export default {
    name: 'Tags',

    components: {
        Header,
        FilterDialog,
        SearchComponent,
        SettingsMenu,
        Table,
    },

    data() {
        return {
            filter: {
                name: '',
                status: 'active',
                entityTypes: [],
            },
            originalMembers: [],
            roleDetail: null,
            roles: [],
            filtersMembers: [],
            projects: [],
            tags: [],
            headers: [
                {
                    text: this.$t('user'),
                    align: 'start',
                    sortable: true,
                    value: 'user',
                    class: 'elevation-0 rounded-l-lg',
                    checked: true,
                },
                {
                    text: this.$t('role'),
                    value: 'role',
                    sortable: true,
                    checked: true,
                },
                {
                    text: this.$t('project'),
                    value: 'project',
                    sortable: true,
                    checked: true
                },
                {
                    text: this.$t('tags'),
                    value: 'tags',
                    sortable: true,
                    checked: true,
                },
                {
                    text: this.$t('email'),
                    value: 'email',
                    sortable: true,
                    checked: true,
                },
            ],
            isLoading: false,
            menuOpen: false,
            errorMessage: '',
            showCreateUpdateDialog: false,
            showArchiveConfirmDialog: false,
            showDeleteConfirmDialog: false,
            selectedTag: {
                uid: '',
                name: '',
                description: '',
                entityTypes: [],
            },
            selectedMembers: [],
            clearSelection: false,
        }
    },

    computed: {
        ...mapState(['currentAccount']),
        filteredMenuHeaders() {
            const filtered = this.headers.filter((header) => header.value != 'actions');
            return filtered;
        },
        filteredHeaders() {
            return this.headers.filter((header) => {
                const filterItem = this.filteredMenuHeaders.find((item) => item.text === header.text);
                return filterItem ? filterItem.checked : true;
            });
        },
        getProjectsName() {
            return this.projects.map(project => project.name)
        },
        getTagsName() {
            return this.tags.map(tag => tag.name)
        },
        getRoleMembers() {
            return this.roleDetail?.members
        },
        getSelectedMembersCount() {
            return this.selectedMembers.length
        },
        selectedMembersHasData() {
            return this.selectedMembers.length > 0
        },
        getRolesName() {
            const currentRouteId = this.$route.params.id;
            return this.roles
                .filter(role => role.uid !== currentRouteId)
                .map(role => {
                    return {
                        roleName: role.name,
                        roleId: role.uid,
                    };
                });
        },
        selectedMembersObj() {
            return this.selectedMembers?.map(item => {
                return {
                    userId: item.uid,
                }
            })
        },
        selectedMembersUserIds() {
            return this.selectedMembers?.map(item => item.uid) || [];
        }
    },

    watch: {
        'filter.name': {
            handler: _.debounce(function () {
                this.filtersMembersFunc()
            }, 500),
        },
    },

    async mounted() {
        await this.init(this.currentAccount.handle);
    },
    async beforeRouteUpdate(to, from, next) {
        const handle = to.params.handle;
        if (handle && handle !== from.params.handle) {
            try {
                await this.init(handle);
                next();
            } catch (error) {
                next();
            }
        } else {
            next();
        }
    },

    methods: {
        async onSelectRole(roleId, roleName) {
            try {
                let newRole = roleName;
                let userCount = this.getSelectedMembersCount;
                let members = this.selectedMembersObj?.map(item => {
                    return {
                        ...item,
                        roleId: this.$route.params.id,
                    };
                });
                await this.reAssignRole(roleId, { members });
                this.menuOpen = false;
                await this.getRoleDetail(this.currentAccount.handle);
                this.clearSelection = false;
                this.$nextTick(() => {
                    this.clearSelection = true;
                });
                await this.$swal({
                    title: this.$t('reassignToRole', { userCount, newRole }),
                    icon: 'success',
                    showConfirmButton: false,
                    position: 'top-end',
                    timer: 3000,
                    toast: true,
                });
            } catch (error) {
                console.error('An error occurred:', error);
                await this.$swal({
                    title: this.$t('errorOccurred'),
                    text: error.message,
                    icon: 'error',
                    showConfirmButton: true,
                    position: 'top-end',
                    timer: 3000,
                    toast: true,
                });
            } finally {
                this.selectedMembers = [];
            }
        },
        applyFilter(result) {

        },
        async onDeleteMembers() {
            try {
                const userIds = this.selectedMembersUserIds;
                const roleId = this.$route.params.id;

                await this.deleteMembersFromRole(roleId, { userIds });
                await this.init(this.currentAccount.handle);
                this.selectedMembers = [];
                await this.$swal({
                    title: this.$t('user successfully deleted'),
                    icon: 'success',
                    showConfirmButton: false,
                    position: 'top-end',
                    timer: 3000,
                    toast: true,
                });
            } catch (error) {
                console.error('An error occurred:', error);
                await this.$swal({
                    title: this.$t('errorOccurred'),
                    text: error.message,
                    icon: 'error',
                    showConfirmButton: true,
                    position: 'top-end',
                    timer: 3000,
                    toast: true,
                });
            } finally {
                this.selectedMembers = [];
            }
        },
        backToRoles() {
            this.$router.push({ name: 'Roles' });
        },
        async init(handle) {
            await this.getRoles(handle);
            await this.getRoleDetail(handle);
            await this.getProjects(handle);
            await this.getTags(handle);
        },
        async reAssignRole(roleId, payload) {
            const roleMembersService = makeRoleService(this.$api);
            try {
                const response = await roleMembersService.reAssignRole(this.currentAccount.handle, roleId, payload);
            } catch (err) {
                this.errorMessage = err.message;
            }
        },
        async deleteMembersFromRole(roleId, payload) {
            const roleMembersService = makeRoleService(this.$api);
            try {
                const response = await roleMembersService.deleteMembersFromRole(this.currentAccount.handle, roleId, payload);
            } catch (err) {
                this.errorMessage = err.message;
            }
        },
        async getRoleDetail(handle) {
            const id = this.$route.params.id;
            const roleMembersService = makeRoleService(this.$api);
            try {
                const response = await roleMembersService.getRoleDetails(handle, id);
                this.roleDetail = response.data;
                this.originalMembers = response.data?.members
            } catch (err) {
                this.errorMessage = err.message;
            }

            this.filtersMembersFunc();
        },
        async getRoles(handle) {
            const roleMembersService = makeRoleService(this.$api);
            try {
                const response = await roleMembersService.getRoles(handle);
                this.roles = response.data?.roles;
            } catch (err) {
                this.errorMessage = err.message;
            }
        },
        async getProjects(handle) {
            const projectService = makeProjectService(this.$api);
            try {
                const params = {
                    status: 'active',
                };
                const queryString = new URLSearchParams(params).toString();
                const response = await projectService.getProjects(handle, queryString);
                this.projects = response.data?.projects;
            } catch (err) {
                this.errorMessage = err.message;
            }
        },
        async getTags(handle) {
            const tagService = makeTagService(this.$api);
            try {
                const response = await tagService.getTags(handle);
                this.tags = response.data
            } catch (err) {
                this.errorMessage = err.message;
            }
        },

        updateFilterStatus(value) {
            this.filter.status = value;

            this.filtersMembersFunc();
        },

        updateFilterTypes(value) {
            this.filter.entityTypes = value

            this.filtersMembersFunc();
        },

        filtersMembersFunc() {
            let filtersMembers = _.cloneDeep(this.originalMembers)

            if (this.filter.name) {
                const name = this.filter.name.toLowerCase();
                filtersMembers = filtersMembers.filter(item => item.firstName.includes(name) || item.lastName.includes(name))
            }
            if (this.filter.status) {
                filtersMembers = filtersMembers.filter(item => this.filter.status === 'active' && !item.archivedAt || this.filter.status === 'archived' && item.archivedAt)
            }

            if (this.filter.entityTypes.length > 0) {
                filtersMembers = filtersMembers.filter(item => this.filter.entityTypes.find(tagType => item.entityTypes.includes(tagType.name)))
            }

            this.filtersMembers = filtersMembers
        },
        onCheckboxSelected(selectedItems) {
            this.selectedMembers = selectedItems;
        },
    }
}
</script>